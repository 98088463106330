import {
    BalList,
    BalListItem,
    BalListItemContent,
    BalListItemTitle,
    BalListItemSubtitle,
    BalListItemIcon,
    BalIcon,
    BalText,
    BalSpinner,
} from "@baloise/design-system-next-components-react";
import React from "react";
import * as style from "./searchresults.module.scss";
import Premium from "../../../../assets/icons/premium.svg";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { getCityName, getStreetName } from "@utils/helpers";
import { Language } from "@utils/enums";

type SearchResultProps = {
    results: any;
    setSelectedRepairer: any;
    searchedForResults: any;
    searching: boolean;
    language: Language;
};

const SearchResults: React.FC<SearchResultProps> = ({
    results,
    setSelectedRepairer,
    searchedForResults,
    searching,
    language,
}) => {
    const selectRepairer = (repairer: any) => {
        setSelectedRepairer(repairer);
    };
    const { t } = useTranslation();
    return (
        <div
            className={`${style.results} pb-none ${
                results?.length > 0 && "filled"
            }`}
        >
            <div className="container is-fluid">
                {searching ? (
                    <div className="is-flex is-justify-content-center is-align-items-center">
                        <BalSpinner />
                    </div>
                ) : (
                    <BalList border size="large">
                        {results && (
                            <>
                                {results.map((result: any, key: number) => {
                                    return (
                                        <BalListItem
                                            key={key}
                                            clickable
                                            onBalNavigate={() =>
                                                selectRepairer(result)
                                            }
                                            className={style.listItem}
                                        >
                                            <BalListItemContent>
                                                <BalListItemTitle level="h5">
                                                    <span className="is-flex is-align-items-center">
                                                        {result.name}
                                                        {result.type ===
                                                            "Premium plus" && (
                                                            <img
                                                                src={Premium}
                                                                width="20"
                                                            />
                                                        )}
                                                    </span>
                                                </BalListItemTitle>
                                                <BalListItemSubtitle>
                                                    <BalText
                                                        space="none"
                                                        color="primary"
                                                    >
                                                        <>
                                                            {getStreetName(
                                                                result.address,
                                                                language,
                                                            )}{" "}
                                                            {
                                                                result.address
                                                                    .houseNumber
                                                            }
                                                            ,{" "}
                                                            {
                                                                result.address
                                                                    .postalCode
                                                            }{" "}
                                                            {getCityName(
                                                                result.address,
                                                                language,
                                                            )}
                                                            <br />
                                                            {
                                                                result
                                                                    .contactDetails
                                                                    .telephoneNumber
                                                            }{" "}
                                                            <br />
                                                            {
                                                                result
                                                                    .contactDetails
                                                                    .email
                                                            }
                                                        </>
                                                    </BalText>
                                                </BalListItemSubtitle>
                                            </BalListItemContent>
                                            <BalListItemIcon right>
                                                <BalIcon
                                                    name="nav-go-right"
                                                    size="xsmall"
                                                ></BalIcon>
                                            </BalListItemIcon>
                                        </BalListItem>
                                    );
                                })}
                            </>
                        )}
                        {searchedForResults && results?.length === 0 && (
                            <BalText className="mt-small">
                                {t("noresults")}
                            </BalText>
                        )}
                    </BalList>
                )}
            </div>
        </div>
    );
};

export default SearchResults;
