import { useState } from "react";
import { RepairerResult, SearchObject } from "@interfaces";
import { getClient } from "@utils/helpers/axios/client";
import { TabValues } from "@utils/enums";
import { searchResultState } from "@states";
import { useSetRecoilState } from "recoil";
import useDebouncedEffect from "../DebounceEffect";

let cancelablePromise: { promise: any; cancel?: () => void } | null = null;

export const useResult = (searchObject: SearchObject | undefined) => {
    const [results, setResults] = useState<Array<RepairerResult> | undefined>(
        undefined,
    );

    const [searchedForResults, setSearchedForResults] = useState<any>(false);
    const [searching, setSearching] = useState<boolean>(false);

    const setSearchResult = useSetRecoilState(searchResultState);

    const resetResults = () => {
        setResults(undefined);
    };

    const getItems = async (url: string) => {
        return await getClient().get(url);
    };

    const makeCancelable = (promise: Promise<unknown>) => {
        let hasCanceled_ = false;

        const wrappedPromise = new Promise((resolve, reject) => {
            promise.then((val) =>
                hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
            );
            promise.catch((error) =>
                hasCanceled_ ? reject({ isCanceled: true }) : reject(error),
            );
        });

        return {
            promise: wrappedPromise,
            cancel() {
                hasCanceled_ = true;
            },
        };
    };

    const searchForItems = () => {
        let url = `${process.env.GATSBY_API_URL}/api/find-a-repairer/repairers`;

        setSearchResult([]);
        if (searchObject) {
            cancelablePromise ? (cancelablePromise as any).cancel() : false;
            if (
                searchObject?.searchBy === TabValues.Name &&
                searchObject.searchFields.name !== ""
            ) {
                setSearching(true);
                url += "?name=" + searchObject.searchFields.name;
                cancelablePromise = makeCancelable(getItems(url));

                cancelablePromise.promise
                    .then((resp: any) => {
                        setResults(resp.data);
                        setSearchResult(resp.data);
                        setSearchedForResults({
                            ...searchedForResults,
                            [searchObject.searchBy]: true,
                        });
                    })
                    .catch((error: any) => {
                        console.log("error", error);
                    })
                    .finally(() => {
                        setSearching(false);
                    });
            } else if (
                searchObject?.searchBy === TabValues.Location &&
                searchObject.searchFields.brand &&
                searchObject.searchFields.location
            ) {
                setSearching(true);
                url += "?brand=" + searchObject.searchFields.brand;
                url +=
                    "&postalCode=" +
                    searchObject.searchFields.location.postalCode;
                url +=
                    "&latitude=" + searchObject.searchFields.location?.latitude;
                url +=
                    "&longitude=" +
                    searchObject.searchFields.location?.longitude;
                getItems(url)
                    .then((resp) => {
                        setResults(resp.data);
                        setSearchedForResults({
                            ...searchedForResults,
                            [searchObject.searchBy]: true,
                        });
                        setSearchResult(resp.data);
                    })
                    .catch((error) => {
                        console.log("error", error);
                    })
                    .finally(() => {
                        setSearching(false);
                    });
            }
        }
    };

    useDebouncedEffect(
        () => {
            searchForItems();
        },
        300,
        [searchObject],
    );

    return {
        results,
        resetResults,
        searchedForResults,
        searching,
        searchForItems,
    };
};
