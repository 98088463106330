import { Props } from "@baloise/design-system-next-components/dist/types/props";
export interface SearchField {
    id: number;
    fieldname: string;
    label: string;
    placeholder: string;
    columnClass: string;
    fieldType: Props.BalInputInputType;
    icon?: string;
    filter?: Props.BalSelectFilter;
    noData: string;
}

const SearchByLocationAndBrandFields: Array<SearchField> = [
    {
        id: 1,
        fieldname: "location",
        label: "",
        placeholder: "searchbylocation.location.placeholder",
        columnClass: "is-full",
        fieldType: "text",
        // icon: "search",
        filter: "includes",
        noData: "searchbylocation.location.nodata",
    },
    {
        id: 2,
        fieldname: "brand",
        label: "",
        placeholder: "searchbylocation.brand.placeholder",
        columnClass: "is-full",
        fieldType: "text",
        // icon: "search",
        filter: "starts-with",
        noData: "searchbylocation.brand.nodata",
    },
];

const SearchByNameFields: Array<SearchField> = [
    {
        id: 1,
        fieldname: "name",
        label: "",
        placeholder: "searchbyname.name.placeholder",
        columnClass: "is-full",
        fieldType: "text",
        icon: "search",
        noData: "",
    },
];

const MapCameraDefault = {
    zoom: 8,
    center: [4.434799359094061, 51.19458018572897],
};

const CityViewZoomLevel = 11;

export {
    SearchByLocationAndBrandFields,
    SearchByNameFields,
    MapCameraDefault,
    CityViewZoomLevel,
};
