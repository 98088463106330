import { useCallback, useEffect } from "react";

const useDebouncedEffect = (effect: any, delay: number, deps: any) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callback = useCallback(effect, deps);
    useEffect(() => {
        const handler = setTimeout(() => {
            callback();
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [callback, delay]);
};

export default useDebouncedEffect;
