import {
    BalCard,
    BalTabs,
    BalTabItem,
    BalHeading,
} from "@baloise/design-system-next-components-react";
import React, { useState } from "react";
import SearchByLocationAndBrand from "./SearchByLocationAndBrand";
import SearchByName from "./SearchByName";
import SearchResults from "./SearchResults";
import * as style from "./searchmodule.module.scss";
import SelectedRepairer from "./SelectedRepairer";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "../../../hooks/MediaQuery";
import { Language, MediaQuery } from "@utils/enums";

/**
 * SearchModule
 */

type SearchModuleProps = {
    results: any;
    setSearchObject: any;
    selectedRepairer: any;
    setSelectedRepairer: any;
    searchedForResults: any;
    resetResults: any;
    searching: boolean;
    language: Language;
    searchForItems: any;
};

const SearchModule: React.FC<SearchModuleProps> = ({
    results,
    setSearchObject,
    selectedRepairer,
    setSelectedRepairer,
    searchedForResults,
    resetResults,
    searching,
    language,
    searchForItems,
}) => {
    const [activeTab, setActiveTab] = useState<string>("location");

    const { t } = useTranslation();

    const setTabAndClearData = (tab: string) => {
        setActiveTab(tab);
        resetResults();
    };

    const isLowWideScreen = useMediaQuery(MediaQuery.LowWidescreen);

    return (
        <div className={style.searchModule}>
            <BalCard>
                {selectedRepairer ? (
                    <SelectedRepairer
                        selectedRepairer={selectedRepairer}
                        setSelectedRepairer={setSelectedRepairer}
                        language={language}
                    />
                ) : (
                    <>
                        <div className={style.tabs}>
                            {!isLowWideScreen && (
                                <BalHeading
                                    space="top"
                                    className="has-border-bottom-normal pb-normal mb-small"
                                    level="h5"
                                >
                                    <>{t("searchby.title")}</>
                                </BalHeading>
                            )}
                            <BalTabs
                                value={activeTab}
                                onBalChange={(event) =>
                                    event.detail === "location" ||
                                    event.detail === "name"
                                        ? setTabAndClearData(event.detail)
                                        : null
                                }
                                expanded
                                className={`pt-none pb-xx-small ${style.tabcontainer}`}
                            >
                                <BalTabItem
                                    value="location"
                                    label={t("searchbylocation.tab.title")}
                                >
                                    <SearchByLocationAndBrand
                                        setSearchObject={setSearchObject}
                                        resetResults={resetResults}
                                        activeTab={activeTab}
                                        searchForItems={searchForItems}
                                    />
                                </BalTabItem>
                                <BalTabItem
                                    value="name"
                                    label={t("searchbyname.tab.title")}
                                >
                                    <SearchByName
                                        setSearchObject={setSearchObject}
                                        activeTab={activeTab}
                                    />
                                </BalTabItem>
                            </BalTabs>
                        </div>
                        <SearchResults
                            results={results}
                            setSelectedRepairer={setSelectedRepairer}
                            searchedForResults={searchedForResults}
                            searching={searching}
                            language={language}
                        />
                    </>
                )}
            </BalCard>
        </div>
    );
};

export default SearchModule;
