import React from "react";
import * as style from "@components/Footer/footer.module.scss";
import { BalFooter } from "@baloise/design-system-next-components-react";
import { useTranslation } from "gatsby-plugin-react-i18next";

/**
 * footer
 */
const Footer: React.FC = () => {
    const { t } = useTranslation();

    const today = new Date();

    return (
        <BalFooter hideLinks={true} hideLanguageSelection={true}>
            <div className={`container ${style.footer}`}>
                <div className={style.linklist}>
                    <span>
                        <a
                            className="has-text-white"
                            target="_blank"
                            rel="noreferrer nofollow"
                            href={
                                process.env.GATSBY_CURRENT_LANGUAGE === "nl"
                                    ? "https://www.baloise.be/nl/over-ons/cookie-policy.html"
                                    : "https://www.baloise.be/fr/a-propos-de-nous/cookie-policy.html"
                            }
                        >
                            {t("footer.links.second")}
                        </a>
                    </span>
                    <span>
                        <>
                            <a
                                className="has-text-white"
                                target="_blank"
                                rel="noreferrer nofollow"
                                href={"mailto:" + t("errormail")}
                            >
                                {t("foundanerror")}
                            </a>
                        </>
                    </span>
                </div>
                <div className={style.copyright}>
                    <span>
                        &copy; {today.getFullYear()} {t("footer.branding")}
                    </span>
                </div>
            </div>
        </BalFooter>
    );
};

export default Footer;
