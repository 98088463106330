import { logError } from "@utils/logger";
import { useState } from "react";
import { useWindow } from "../Window";
import { getClient } from "@utils/helpers/axios/client";
import useDebouncedEffect from "../DebounceEffect";

export const generateApiUrl = (appendix: string) => {
    return process.env.GATSBY_API_URL !== ""
        ? `${process.env.GATSBY_API_URL}/api/${appendix}`
        : `/api/${appendix}`;
};

export function useB2BCities(typeAheadCity: string | undefined) {
    const { hasWindow } = useWindow();
    const [B2BCities, setB2BCities] = useState<any>();
    const [citiesLoading, setCitiesLoading] = useState<boolean>(false);

    const getCitiesFromData = (searchString: string) => {
        try {
            const client = getClient().get(
                generateApiUrl("address/cities?filter=" + searchString),
            );
            setCitiesLoading(true);
            Promise.resolve(client)
                .then((r) => {
                    setB2BCities(r.data);
                })
                .catch((error) => {
                    logError(error);
                })
                .finally(() => {
                    setCitiesLoading(false);
                });
        } catch (err) {
            logError(err);
        }
    };

    useDebouncedEffect(
        () => {
            if (
                B2BCities?.length > 0 ||
                (typeAheadCity === "" && B2BCities === undefined)
            ) {
                setCitiesLoading(false);
            }
        },
        300,
        [B2BCities, typeAheadCity],
    );

    useDebouncedEffect(
        () => {
            if (hasWindow && typeAheadCity && typeAheadCity.length > 1) {
                getCitiesFromData(typeAheadCity);
            } else {
                setB2BCities(undefined);
            }
        },
        300,
        [hasWindow, typeAheadCity],
    );

    return { B2BCities, citiesLoading };
}
