import { source, layer } from "azure-maps-control";

const bringDataIntoView = (map: any, azdata: any): void => {
    let _options = {
        style: "light",
        padding: 100,
    };
    var bbox = azdata.BoundingBox;
    //Logic that gets all shapes on the map and calculates the bounding box of the map.
    var data: any[] = [];
    var sources = map.sources.getSources();
    sources.forEach(function (s: any) {
        if (s instanceof source.DataSource) {
            data = data.concat(s.toJson().features);
        }
    });
    var bounds = null;
    if (data.length > 0) {
        bounds = bbox.fromData(data);
    }
    var pos = [];
    for (
        var _i = 0, _a = map.markers["markers"].values();
        _i < _a.length;
        _i++
    ) {
        var marker = _a[_i];
        pos.push(marker.getOptions().position);
    }
    if (pos.length > 0) {
        var b = bbox.fromPositions(pos);
        if (bounds === null) {
            bounds = b;
        } else {
            bounds = bbox.merge(bounds, b);
        }
    }
    var l = map.layers.getLayers();
    for (var i = 0; i < l.length; i++) {
        if (l[i] instanceof layer.ImageLayer) {
            var c = bbox.fromPositions(l[i].getOptions().coordinates);
            if (bounds === null) {
                bounds = c;
            } else {
                bounds = bbox.merge(bounds, c);
            }
        }
    }
    if (bounds !== null) {
        var w = bbox.getWidth(bounds);
        var h = bbox.getHeight(bounds);
        //If the bounding box is really small, likely a single point, use center/zoom.
        if (w < 0.000001 || h < 0.000001) {
            map.setCamera({
                center: bbox.getCenter(bounds),
                zoom: 17,
            });
        } else {
            map.setCamera({
                bounds: bounds,
                padding: _options.padding,
            });
        }
    }
};

export default bringDataIntoView;
