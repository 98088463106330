import { useEffect, useState } from "react";

export const useWindow = () => {
    const hasWindow = typeof global?.window !== "undefined";
    const [origin, setOrigin] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (hasWindow) {
            setOrigin(global.window.location.origin);
        }
    }, [hasWindow]);

    const getCookie = (name: string) => {
        const queryString = global.window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get(name);
    };

    return { hasWindow, getCookie, origin };
};
