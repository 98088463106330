import { Language } from "@utils/enums";

export const getCityName = (city: any, language: Language) => {
    const langKey = language === Language.NL ? "cityNl" : "cityFr";

    return city[langKey] ?? "";
};

export const getStreetName = (street: any, language: Language) => {
    const langKey = language === Language.NL ? "streetNl" : "streetFr";

    return street[langKey] ?? "";
};
