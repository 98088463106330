import { useI18next } from "gatsby-plugin-react-i18next";

export const useMetadata = () => {
    const { t } = useI18next();

    const seo = {
        title: t("seo.title"),
        description: t("seo.description"),
    };

    return { seo };
};
