import React, { useEffect, useState } from "react";
import SearchModule from "./SearchModule";
import Map from "./Map";
import * as style from "./findarepairer.module.scss";
import { useResult } from "../../hooks/UseResult";
import { RepairerResult, SearchObject } from "@interfaces";
import {
    BalButton,
    BalText,
} from "@baloise/design-system-next-components-react";
import { useMediaQuery } from "../../hooks/MediaQuery";
import { Language, MediaQuery } from "@utils/enums";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Premium from "../../assets/icons/premium.svg";
import Service from "../../assets/icons/default.svg";

/**
 * FindARepairer
 */

type VEHProps = {
    language: Language;
};

const FindARepairer: React.FC<VEHProps> = ({ language }) => {
    const [searchObject, setSearchObject] = useState<SearchObject>();
    const {
        results,
        resetResults,
        searchedForResults,
        searching,
        searchForItems,
    } = useResult(searchObject);
    const isLandscape = useMediaQuery(MediaQuery.TabletLandscape);
    const [showMap, setShowMap] = useState<boolean>(isLandscape);
    const [isMapFlowFollowed, setIsMapFlowFollowed] = useState<boolean>(false);
    const { t } = useTranslation();
    const [selectedRepairer, setSelectedRepairer] = useState<
        RepairerResult | undefined
    >();

    useEffect(() => {
        if (selectedRepairer === null) {
            isMapFlowFollowed ? setShowMap(true) : setShowMap(false);
        } else {
            setShowMap(false);
        }
    }, [selectedRepairer, isMapFlowFollowed]);

    useEffect(() => {
        showMap ? setIsMapFlowFollowed(showMap) : null;
    }, [showMap]);

    return (
        <>
            <div
                className={`${style.repairer} mt-normal mb-small is-relative is-flex is-flex-direction-column`}
            >
                {!isLandscape && (
                    <div
                        className={`${style.showmapbutton} is-flex is-flex-direction-column`}
                    >
                        <BalButton
                            color="link"
                            className={`is-align-self-flex-end`}
                            onClick={() => setShowMap(!showMap)}
                        >
                            {t("showonmap")}
                        </BalButton>
                    </div>
                )}
                <SearchModule
                    results={results}
                    setSearchObject={setSearchObject}
                    selectedRepairer={selectedRepairer}
                    setSelectedRepairer={setSelectedRepairer}
                    searchedForResults={searchedForResults}
                    resetResults={resetResults}
                    searching={searching}
                    language={language}
                    searchForItems={searchForItems}
                />
                {(isLandscape || (!isLandscape && showMap)) && (
                    <Map
                        results={results}
                        selectedRepairer={selectedRepairer}
                        setSelectedRepairer={setSelectedRepairer}
                        setShowMap={setShowMap}
                        setIsMapFlowFollowed={setIsMapFlowFollowed}
                        language={language}
                    />
                )}
            </div>
            <div className="is-flex-tablet is-align-items-center mb-normal">
                <div className="is-flex is-align-items-center mr-x-small">
                    <BalText space="none" bold>
                        <>{t("legend")}</>
                    </BalText>
                </div>
                <div className="is-flex is-align-items-center mr-normal">
                    <img src={Premium} width="20" />
                    <BalText space="none">
                        <>
                            {t("premiumplus")} {t("approvedbybaloise")}
                        </>
                    </BalText>
                </div>
                <div className="is-flex is-align-items-center">
                    <img src={Service} width="20" />
                    <BalText space="none">
                        <>
                            {t("serviceplus")} {t("serviceplusexplanation")}
                        </>
                    </BalText>
                </div>
            </div>
        </>
    );
};

export default FindARepairer;
