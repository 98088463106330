import {
    BalField,
    BalFieldControl,
    BalFieldLabel,
    BalIcon,
    BalInput,
    BalInputGroup,
} from "@baloise/design-system-next-components-react";
import React, { createRef, useEffect } from "react";
import { SearchByNameFields, SearchField } from "@utils/constants";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { TabValues } from "@utils/enums";

type SearchByNameProps = {
    setSearchObject: any;
    activeTab: string;
};

const SearchByName: React.FC<SearchByNameProps> = ({
    setSearchObject,
    activeTab,
}) => {
    const { t } = useTranslation();

    const inputRef = createRef<any>();

    const onChange = (event: any) => {
        setSearchObject({
            searchBy: TabValues.Name,
            searchFields: {
                name: event.detail,
            },
        });
    };

    useEffect(() => {
        inputRef.current ? (inputRef.current.value = "") : null;
        setSearchObject(undefined);
        // eslint-disable-next-line
    }, [activeTab]);

    return (
        <div className="container is-fluid pb-normal">
            <div className="columns mt-none mb-none">
                <div className="column pb-none">
                    {SearchByNameFields.map(
                        (field: SearchField, key: number) => {
                            return (
                                <BalField key={key} className="mb-none">
                                    {t(field.label) !== "" && (
                                        <BalFieldLabel>
                                            {t(field.label)}
                                        </BalFieldLabel>
                                    )}
                                    <BalFieldControl className="pt-normal pb-none">
                                        <BalInputGroup>
                                            <BalInput
                                                name={field.fieldname}
                                                type={field.fieldType}
                                                placeholder={t(
                                                    field.placeholder,
                                                )}
                                                onBalInput={(event) =>
                                                    onChange(event)
                                                }
                                                ref={inputRef}
                                            ></BalInput>
                                            {field.icon && (
                                                <BalIcon
                                                    name={field.icon}
                                                ></BalIcon>
                                            )}
                                        </BalInputGroup>
                                    </BalFieldControl>
                                </BalField>
                            );
                        },
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchByName;
