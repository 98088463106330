import {
    Map,
    source,
    layer,
    data,
    MapMouseEvent,
    Shape,
    ClusteredProperties,
} from "azure-maps-control";
import { options } from "./SpiderClusterOptions";

const SpiderClusterManager = (
    map: Map,
    clusterLayer: layer.BubbleLayer | layer.SymbolLayer,
    unclustedLayer: layer.BubbleLayer | layer.SymbolLayer,
    highlight: any,
) => {
    let datasource: source.DataSource | undefined;
    let spiderLineLayer!: layer.LineLayer;
    let hoverStateId: string | null = null;
    let spiderDatasourceId: string | undefined;

    let s = clusterLayer.getSource();
    if (typeof s === "string") {
        s = map.sources.getById(s);
    }
    if (s instanceof source.DataSource) {
        datasource = s;
    } else {
        throw "Data source on cluster layer is not supported.";
    }

    let spiderDataSource = new source.DataSource();
    map.sources.add(spiderDataSource);
    spiderDatasourceId = spiderDataSource.getId();

    spiderLineLayer = new layer.LineLayer(
        spiderDataSource,
        undefined,
        options?.stickLayerOptions,
    );

    map.layers.add(spiderLineLayer);
    //Make a copy of the cluster layer options.
    var unclustedLayerOptions = Object.assign({}, unclustedLayer.getOptions());
    unclustedLayerOptions.source = undefined;
    unclustedLayerOptions.filter = [
        "any",
        ["==", ["geometry-type"], "Point"],
        ["==", ["geometry-type"], "MultiPoint"],
    ]; //Only render Point or MultiPoints in this layer.;

    let spiderFeatureLayer: layer.BubbleLayer | layer.SymbolLayer;

    if (unclustedLayer instanceof layer.BubbleLayer) {
        spiderFeatureLayer = new layer.BubbleLayer(
            spiderDataSource,
            undefined,
            unclustedLayerOptions,
        );
    } else {
        unclustedLayerOptions.iconOptions =
            unclustedLayerOptions.iconOptions || {};

        Object.assign(unclustedLayerOptions.iconOptions, {
            allowOverlap: true,
            ignorePlacement: true,
        });

        spiderFeatureLayer = new layer.SymbolLayer(
            spiderDataSource,
            undefined,
            unclustedLayerOptions,
        );
    }

    map.layers.add(spiderFeatureLayer);

    const highlightStick = (e: MapMouseEvent): void => {
        if (e && e.shapes && e.shapes.length > 0 && map) {
            map.getCanvasContainer().style.cursor = "pointer";
        }
    };

    const unhighlightStick = (): void => {
        if (hoverStateId && map) {
            map.map.setFeatureState(
                { source: spiderDatasourceId, id: hoverStateId },
                { hover: false },
            );
            hoverStateId = null;

            map.getCanvasContainer().style.cursor = "grab";
        }
    };

    const hideSpiderCluster = (e?: any): void => {
        if (
            !e ||
            options?.closeWebOnPointClick ||
            //@ts-ignore
            (!options?.closeWebOnPointClick &&
                e.shapes &&
                e.shapes.length > 0 &&
                e.shapes[0] instanceof Shape &&
                e.shapes[0].getId() !== spiderDataSource.getId())
        ) {
            spiderDataSource.clear();
        }
    };

    const showSpiderCluster = (
        cluster: data.Feature<data.Point | data.Geometry, ClusteredProperties>,
    ): void => {
        const opt = options;

        const oldData = spiderDataSource.getShapes();

        if (
            cluster &&
            cluster?.properties?.cluster &&
            datasource &&
            opt?.maxFeaturesInWeb !== undefined &&
            opt?.circleSpiralSwitchover !== undefined &&
            opt?.minCircleLength !== undefined &&
            map
        ) {
            if (
                oldData.length > 0 &&
                oldData[0].getProperties().clusterid ===
                    cluster?.properties?.cluster_id
            ) {
                //No need to reload the spider web.
                return;
            }

            hideSpiderCluster();
            const shapes:
                | Shape
                | data.Geometry
                | data.Feature<data.Geometry, any>
                | data.FeatureCollection
                | data.GeometryCollection
                | (Shape | data.Geometry | data.Feature<data.Geometry, any>)[] =
                [];

            datasource
                .getClusterLeaves(
                    cluster?.properties?.cluster_id,
                    opt.maxFeaturesInWeb,
                    0,
                )
                .then((children) => {
                    if (
                        opt.circleSpiralSwitchover &&
                        opt.minCircleLength &&
                        opt.spiralDistanceFactor &&
                        opt.minSpiralAngleSeperation
                    ) {
                        //Create spider data.
                        const center = cluster.geometry.coordinates;
                        const centerPoint = map?.positionsToPixels([
                            center as data.Position,
                        ])[0];
                        let angle: number = 0;

                        const makeSpiral: boolean =
                            children.length > opt.circleSpiralSwitchover;

                        let legPixelLength: number | undefined;
                        let stepAngle: number | undefined;
                        let stepLength: number | undefined;

                        if (makeSpiral) {
                            legPixelLength = opt.minCircleLength / Math.PI;
                            stepLength = 2 * Math.PI * opt.spiralDistanceFactor;
                        } else {
                            stepAngle = (2 * Math.PI) / children.length;

                            legPixelLength =
                                (opt.spiralDistanceFactor /
                                    stepAngle /
                                    Math.PI /
                                    2) *
                                children.length;

                            if (legPixelLength < opt.minCircleLength) {
                                legPixelLength = opt.minCircleLength;
                            }
                        }

                        for (let i = 0, len = children.length; i < len; i++) {
                            //Calculate spider point feature location.
                            if (makeSpiral && stepLength) {
                                angle +=
                                    opt.minSpiralAngleSeperation /
                                        legPixelLength +
                                    i * 0.0005;
                                legPixelLength += stepLength / angle;
                            } else {
                                angle = stepAngle ? stepAngle * i : 0;
                            }

                            if (centerPoint) {
                                const pos = map?.pixelsToPositions([
                                    [
                                        centerPoint[0] +
                                            legPixelLength * Math.cos(angle),
                                        centerPoint[1] +
                                            legPixelLength * Math.sin(angle),
                                    ],
                                ])[0];

                                if (pos) {
                                    //Create stick to point feature.
                                    shapes.push(
                                        new data.Feature(
                                            new data.LineString([
                                                center as data.Position,
                                                pos,
                                            ]),
                                            null,
                                            i + "",
                                        ),
                                    );

                                    //Create point feature in spiral that contains same metadata as parent point feature.
                                    const c = children[i];
                                    const id =
                                        c instanceof Shape ? c.getId() : c.id;

                                    //Make a copy of the properties.
                                    const p = Object.assign(
                                        {},
                                        c instanceof Shape
                                            ? c.getProperties()
                                            : c.properties,
                                    );
                                    p.stickId = i + "";
                                    p.parentId = id;
                                    p.cluster = cluster?.properties?.cluster_id;

                                    shapes.push(
                                        new data.Feature(
                                            new data.Point(pos),
                                            p,
                                        ),
                                    );
                                }
                            }
                        }
                    }

                    spiderDataSource.add(shapes);
                })
                .catch((err) => {
                    console.log("err", err, cluster);
                    highlight(cluster);
                });
        }
    };

    const layerClickEvent = (event: MapMouseEvent) => {
        if (event && event.shapes && event.shapes.length > 0) {
            let prop;
            let pos:
                | data.Position
                | data.Position[]
                | data.Position[][]
                | data.Position[][][]
                | undefined;
            let layerShape: Shape | undefined;
            let cluster:
                | data.Feature<data.Point | data.Geometry, any>
                | undefined;

            if (event.shapes[0] instanceof Shape) {
                layerShape = event.shapes[0];
                prop = layerShape.getProperties();
                pos = layerShape.getCoordinates();
            } else {
                cluster = event.shapes[0];
                prop = cluster.properties;
                pos = cluster.geometry.coordinates;
            }

            if (cluster && prop.cluster === true && map) {
                const zoom = map?.getCamera().zoom;
                if (
                    options &&
                    datasource &&
                    map &&
                    zoom &&
                    options.maxFeaturesInWeb &&
                    prop.point_count > options.maxFeaturesInWeb
                ) {
                    map.setCamera({
                        zoom: zoom + 2,
                        center: pos,
                    });
                } else {
                    showSpiderCluster(cluster);
                }
            } else if (typeof prop.cluster === "number") {
                highlight(event);
            } else if (!cluster && layerShape) {
                highlight(event);
            } else if (options?.closeWebOnPointClick) {
                hideSpiderCluster();
            }

            event.preventDefault();
        }
    };

    const mapEvents = map.events;

    mapEvents.add("click", hideSpiderCluster);
    mapEvents.add("mouseleave", spiderFeatureLayer, unhighlightStick);
    mapEvents.add("mousemove", spiderFeatureLayer, highlightStick);
    mapEvents.add("click", clusterLayer, layerClickEvent);
    mapEvents.add("click", spiderFeatureLayer, layerClickEvent);
    mapEvents.add("click", unclustedLayer, layerClickEvent);
    mapEvents.add("zoom", hideSpiderCluster);
};

export default SpiderClusterManager;
