import React from "react";
import { graphql } from "gatsby";
import Layout from "@containers/Layout";
import FindARepairer from "@components/FindARepairer";
import { Helmet } from "gatsby-plugin-react-i18next";
import { useMetadata } from "../hooks/useMetadata";

const Index = ({ pageContext }: any) => {
    const { seo } = useMetadata();
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />
                <link rel="icon" type="image/x-icon" href="/favicon.ico" />

                <script
                    id="ga4"
                    type="text/javascript"
                    src="/scripts/analytics.js"
                />
            </Helmet>
            <noscript>
                <iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-TFJ42F5"
                    height="0"
                    width="0"
                    style={{ display: "none", visibility: "hidden" }}
                ></iframe>
            </noscript>
            <FindARepairer language={pageContext.language} />
        </Layout>
    );
};

export default Index;

// export { SEO as Head } from "@components";

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
