import { TypeAheadObject } from "@interfaces";
import { useState, useEffect } from "react";
import { useB2BCars } from "../B2BCar";
import { useB2BCities } from "../B2BCities";

export function useApi(searchObject: TypeAheadObject | undefined) {
    const [apiData, setApiData] = useState<any | undefined>(undefined);
    const { B2BCities } = useB2BCities(searchObject?.location);
    const { B2BCars, clearCars } = useB2BCars(searchObject?.brand);

    useEffect(() => {
        setApiData((apiData: any) => ({
            ...apiData,
            location:
                B2BCities?.length > 0
                    ? (B2BCities.map((c: any) => {
                          let generatedLabel = c.postalCode;
                          if (c.names) {
                              Object.values(c.names).forEach((name) => {
                                  generatedLabel += ` - ${name}`;
                              });
                          }

                          return { value: c.id, label: generatedLabel };
                      }) as Array<any>)
                    : undefined,
            brand:
                B2BCars?.length > 0
                    ? B2BCars.map((c: string) => {
                          return { value: c, label: c };
                      })
                    : undefined,
        }));
    }, [B2BCities, B2BCars]);

    return { apiData, B2BCities, B2BCars, clearCars };
}
