import { atom, selector } from "recoil";
import { RepairerResult, userDataInterface } from "@interfaces";
import { persistAtom } from "@states/persist";
import { MapCameraDefault } from "@utils/constants";

export const searchResultState = atom({
    key: "searchResult",
    default: [] as RepairerResult[],
    effects_UNSTABLE: [persistAtom],
});

export const contextState = atom({
    key: "contextState",
    default: "",
    effects_UNSTABLE: [persistAtom],
});

export const userDataState = selector({
    key: "userData",
    get: ({ get }) => {
        return {
            context: get(contextState),
            searchResult: get(searchResultState),
        } as userDataInterface;
    },
});

export const mapCameraState = atom({
    key: "mapCameraState",
    default: MapCameraDefault,
    effects_UNSTABLE: [persistAtom],
});
