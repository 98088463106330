import React from "react";
import {
    BalLogo,
    BalHeading,
    // BalButtonGroup,
} from "@baloise/design-system-next-components-react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as style from "./header.module.scss";

/**
 * Header
 */
const Header: React.FC = () => {
    const { t } = useTranslation();

    return (
        <header className={`has-background-blue ${style.header}`}>
            <div className={`container ${style.navbar}`}>
                <div className={style.navbarbrand}>
                    <BalLogo color="white" />
                </div>
                <div className={style.navbarcontent}>
                    <BalHeading
                        className="has-text-white"
                        color="white"
                        level="h5"
                        space="none"
                    >
                        <>{t("header.title")}</>
                    </BalHeading>
                </div>
            </div>
        </header>
    );
};

export default Header;
