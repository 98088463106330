/**
 * A token aware api client for usage in the authenticated zone
 */
import axios, { AxiosInstance } from "axios";

declare global {
    // eslint-disable-next-line no-var
    var __USER_PRESENT__: boolean;
}

/**
 * Test if a call needs to be done with credentials
 */
let client: AxiosInstance | null = null;

/**
 * Creates a client that will automatically set window.location.href to the
 * correct login url when a 401 was received from the server
 */
const create = () => {
    const client = axios.create();

    return client;
};

/**
 * Gets or creates a client and returns instance when called
 */
export const getClient = () => {
    if (!client) {
        client = create();
    }
    return client;
};
