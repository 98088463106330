import {
    BalButton,
    BalCard,
    BalCardContent,
    BalHeading,
    BalIcon,
    BalText,
} from "@baloise/design-system-next-components-react";
import { RepairerResult } from "@interfaces";
import { MediaQuery } from "@utils/enums";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { useMediaQuery } from "../../../../hooks/MediaQuery";
import BrandList from "./BrandList";
import * as style from "./selectedrepairer.module.scss";
import Premium from "../../../../assets/icons/premium.svg";
import Service from "../../../../assets/icons/default.svg";
import { getCityName, getStreetName } from "@utils/helpers";

/**
 * SearchModule
 */

type SelectedRepairerProps = {
    selectedRepairer: RepairerResult;
    setSelectedRepairer: any;
    language: string;
};

const SelectedRepairer: React.FC<SelectedRepairerProps> = ({
    selectedRepairer,
    setSelectedRepairer,
    language,
}) => {
    const { t } = useTranslation();
    const isLandscape = useMediaQuery(MediaQuery.TabletLandscape);

    const checkValueAndReturnStripeWhenEmpty = (
        value: string,
        linkAddition?: string,
    ) => {
        return value && value.length > 0 && value !== ""
            ? linkAddition !== null && linkAddition !== undefined
                ? `<a target="_blank" href="${linkAddition}${value}">${value}</a>`
                : value
            : "-";
    };

    return (
        <>
            <div className="has-border-bottom-normal pb-medium">
                <div
                    className={
                        isLandscape
                            ? "container is-fluid is-flex is-align-items-center"
                            : "container is-fluid is-flex is-align-items-center"
                    }
                >
                    <BalButton
                        square
                        size="small"
                        color="info"
                        icon="nav-go-left"
                        onClick={() => setSelectedRepairer(null)}
                    ></BalButton>
                    <BalHeading space="none" level="h5" className="ml-small">
                        <>{selectedRepairer.name}</>
                    </BalHeading>
                    <div></div>
                </div>
            </div>
            <div
                className={
                    isLandscape
                        ? "container is-fluid"
                        : "container is-fluid mb-small"
                }
            >
                <div
                    className={`${style.repairerDetails} is-flex is-flex-direction-column mt-small`}
                >
                    {selectedRepairer.type &&
                        selectedRepairer.type === "Premium plus" && (
                            <div className="mb-normal is-flex is-align-items-flex-start">
                                <img src={Premium} width="20" />
                                <div className="ml-x-small">
                                    <BalHeading level="h6">
                                        <>
                                            <span className="is-flex is-align-items-center">
                                                {selectedRepairer.type.toUpperCase()}
                                            </span>
                                        </>
                                    </BalHeading>
                                    <BalText size="small">
                                        <>{t("approvedbybaloise")}</>
                                    </BalText>
                                </div>
                            </div>
                        )}
                    {selectedRepairer.type &&
                        selectedRepairer.type === "Service plus" && (
                            <div className="mb-normal is-flex is-align-items-flex-start">
                                <img src={Service} width="20" />
                                <div className="ml-x-small">
                                    <BalHeading level="h6">
                                        <>
                                            <span className="is-flex is-align-items-center">
                                                {selectedRepairer.type.toUpperCase()}
                                            </span>
                                        </>
                                    </BalHeading>
                                    <BalText size="small">
                                        <>{t("serviceplusexplanation")}</>
                                    </BalText>
                                </div>
                            </div>
                        )}
                    <div>
                        <BalHeading level="h6">
                            <>{t("selectedrepairer.phone")}</>
                        </BalHeading>
                        <BalText>
                            <>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: checkValueAndReturnStripeWhenEmpty(
                                            selectedRepairer.contactDetails
                                                ?.telephoneNumber,
                                            "tel:",
                                        ),
                                    }}
                                ></span>
                            </>
                        </BalText>
                    </div>
                    <div>
                        <BalHeading level="h6">
                            <>{t("selectedrepairer.email")}</>
                        </BalHeading>
                        <BalText>
                            <>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: checkValueAndReturnStripeWhenEmpty(
                                            selectedRepairer.contactDetails
                                                ?.email,
                                            "mailto:",
                                        ),
                                    }}
                                ></span>
                            </>
                        </BalText>
                    </div>
                    <div>
                        <BalHeading level="h6">
                            <>{t("selectedrepairer.adres")}</>
                        </BalHeading>
                        <BalText>
                            <>
                                {getStreetName(
                                    selectedRepairer.address,
                                    language,
                                )}{" "}
                                {selectedRepairer.address?.houseNumber},{" "}
                                {selectedRepairer.address?.postalCode}{" "}
                                {getCityName(
                                    selectedRepairer.address,
                                    language,
                                )}
                            </>
                        </BalText>
                    </div>
                    <div>
                        <BalHeading level="h6">
                            <>{t("selectedrepairer.website")}</>
                        </BalHeading>
                        <BalText>
                            <>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: checkValueAndReturnStripeWhenEmpty(
                                            selectedRepairer.contactDetails
                                                ?.website,
                                            selectedRepairer.contactDetails?.website.includes(
                                                "http://",
                                            )
                                                ? ""
                                                : selectedRepairer.contactDetails?.website.includes(
                                                      "https://",
                                                  )
                                                ? ""
                                                : "https://",
                                        ),
                                    }}
                                ></span>
                            </>
                        </BalText>
                    </div>
                    <BrandList
                        allBrands={selectedRepairer.brands.allBrands}
                        excluded={selectedRepairer.brands.excluded}
                        included={selectedRepairer.brands.included}
                    />
                </div>
                <BalCard color="purple-light">
                    <BalCardContent>
                        <div className="is-flex">
                            <BalIcon name="info"></BalIcon>
                            <div className="pl-small">
                                {t("selectedrepairer.contactrepairer")}
                            </div>
                        </div>
                    </BalCardContent>
                </BalCard>
            </div>
        </>
    );
};

export default SelectedRepairer;
