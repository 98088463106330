import { logError } from "@utils/logger";
import { useState } from "react";
import { useWindow } from "../Window";
import { getClient } from "@utils/helpers/axios/client";
import useDebouncedEffect from "../DebounceEffect";

export const generateApiUrl = (appendix: string) => {
    return process.env.GATSBY_API_URL !== ""
        ? `${process.env.GATSBY_API_URL}/api/${appendix}`
        : `/api/${appendix}`;
};

export function useB2BCars(typeAheadCar: string | undefined) {
    const { hasWindow } = useWindow();
    const [B2BCars, setB2BCars] = useState<any>();
    const [carsLoading, setCarsLoading] = useState<boolean>(false);

    const getCitiesFromData = (searchString: string) => {
        try {
            const client = getClient().get(
                generateApiUrl(
                    `find-a-repairer/brands${
                        searchString !== "" ? "?brandname=" + searchString : ""
                    }`,
                ),
            );
            setCarsLoading(true);
            Promise.resolve(client)
                .then((r) => {
                    setB2BCars(r.data);
                })
                .catch((error) => {
                    logError(error);
                })
                .finally(() => {
                    setCarsLoading(false);
                });
        } catch (err) {
            logError(err);
        }
    };

    useDebouncedEffect(
        () => {
            if (
                B2BCars?.length > 0 ||
                (typeAheadCar === "" && B2BCars === undefined)
            ) {
                setCarsLoading(false);
            }
        },
        300,
        [B2BCars, typeAheadCar],
    );

    useDebouncedEffect(
        () => {
            if (hasWindow && typeAheadCar && typeAheadCar?.length > 1) {
                getCitiesFromData(typeAheadCar);
            } else {
                setB2BCars(undefined);
            }
        },
        300,
        [hasWindow, typeAheadCar],
    );

    const clearCars = () => {
        setB2BCars(undefined);
    };

    return { B2BCars, carsLoading, clearCars };
}
