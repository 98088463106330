import {
    BalButton,
    BalCard,
    BalIcon,
    BalList,
    BalListItem,
    BalListItemContent,
    BalListItemIcon,
    BalListItemSubtitle,
    BalListItemTitle,
} from "@baloise/design-system-next-components-react";
import { RepairerResult } from "@interfaces";
import { MediaQuery } from "@utils/enums";
import { getCityName, getStreetName } from "@utils/helpers";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "../../../hooks/MediaQuery";
import BalAzureMaps from "./BalAzureMaps";
import * as style from "./Map.module.scss";

/**
 * Map
 */

type mapPropTypes = {
    results: any;
    selectedRepairer: any;
    setSelectedRepairer: any;
    setShowMap: any;
    setIsMapFlowFollowed: any;
    language: string;
};

const Map: React.FC<mapPropTypes> = ({
    results,
    selectedRepairer,
    setSelectedRepairer,
    setShowMap,
    setIsMapFlowFollowed,
    language,
}) => {
    const refresh = false;
    const isLandscape = useMediaQuery(MediaQuery.TabletLandscape);

    const [mapData, setMapData]: any = useState();
    const [capturedRepairer, setCapturedRepairer] = useState();
    const [mobileRepairer, setMobileRepairer] = useState<
        RepairerResult | undefined | null
    >();

    const { t } = useTranslation();

    useEffect(() => {
        const foundRepairer = results?.find(
            (r: any) => r.number === capturedRepairer,
        );
        if (foundRepairer) {
            if (!isLandscape) {
                setMobileRepairer(foundRepairer);
            } else {
                setSelectedRepairer(foundRepairer);
                setMobileRepairer(foundRepairer);
            }
        } else {
            setSelectedRepairer(null);
            setMobileRepairer(null);
        }
        // eslint-disable-next-line
    }, [capturedRepairer, isLandscape]);

    enum Icons {
        Premium = "premium",
        PremiumHover = "premium_hover",
        Default = "default",
        DefaultHover = "default_hover",
    }

    const getIcon = (item: any, selectedRepairer: any) => {
        if (selectedRepairer?.number === item.number) {
            return item.type === "Premium plus"
                ? Icons.PremiumHover
                : Icons.DefaultHover;
        }
        return item?.type === "Premium plus" ? Icons.Premium : Icons.Default;
    };

    useEffect(() => {
        if (results && results.length > 0) {
            setMapData({
                type: "FeatureCollection",
                features: results.map((item: any) => {
                    return {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [
                                Number(item.coordinates.longitude),
                                Number(item.coordinates.latitude),
                            ],
                        },
                        properties: {
                            id: item.number,
                            name: item.name,
                            brokerContent: item,
                            type: getIcon(item, selectedRepairer),
                        },
                    };
                }),
            });
        } else {
            setMapData(undefined);
        }
        // eslint-disable-next-line
    }, [results, selectedRepairer]);

    return (
        <>
            <BalAzureMaps
                mapData={mapData}
                refresh={refresh}
                selectedRepairer={selectedRepairer}
                selectARepairer={setCapturedRepairer}
            />
            {!isLandscape && (
                <div className={style.mapContent}>
                    <div className={style.mapContentButtons}>
                        <BalButton
                            expanded
                            onClick={() => {
                                setShowMap(false);
                                setIsMapFlowFollowed(false);
                            }}
                        >
                            {t("showallresultsonlist")}
                        </BalButton>
                    </div>
                    {mobileRepairer && (
                        <BalCard className={style.mapContentDetails}>
                            <BalList
                                border
                                size="large"
                                className="pb-xxxx-large px-medium"
                            >
                                <BalListItem
                                    clickable
                                    onBalNavigate={() => {
                                        setSelectedRepairer(mobileRepairer);
                                    }}
                                    className={style.listItem}
                                >
                                    <BalListItemContent>
                                        <BalListItemTitle>
                                            <span className="is-flex is-align-items-center">
                                                {mobileRepairer.name}
                                                {mobileRepairer.type ===
                                                    "Premium plus" && (
                                                    <div
                                                        className={
                                                            style.promoted
                                                        }
                                                    ></div>
                                                )}
                                            </span>
                                        </BalListItemTitle>
                                        <BalListItemSubtitle>
                                            {getStreetName(
                                                mobileRepairer.address,
                                                language,
                                            )}{" "}
                                            {mobileRepairer.address.houseNumber}
                                            ,{" "}
                                            {mobileRepairer.address.postalCode}{" "}
                                            {getCityName(
                                                mobileRepairer.address,
                                                language,
                                            )}
                                            <br />
                                            {
                                                mobileRepairer.contactDetails
                                                    .telephoneNumber
                                            }{" "}
                                            <br />
                                            {
                                                mobileRepairer.contactDetails
                                                    .email
                                            }
                                        </BalListItemSubtitle>
                                    </BalListItemContent>
                                    <BalListItemIcon right>
                                        <BalIcon
                                            name="nav-go-right"
                                            size="xsmall"
                                        ></BalIcon>
                                    </BalListItemIcon>
                                </BalListItem>
                            </BalList>
                        </BalCard>
                    )}
                </div>
            )}
        </>
    );
};

export default Map;
