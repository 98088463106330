export enum MediaQuery {
    Mobile = "(max-width: 769px)",
    TabletPortrait = "(min-width: 769px)",
    TabletLandscape = "(min-width: 1024px)",
    TabletLandscapeDown = "(max-width: 1024px)",
    Desktop = "(min-width: 1200px)",
    Widescreen = "(min-width: 1800px)",
    LowWidescreen = "(max-width: 1800px) and (min-width: 1024px) and (max-height: 700px) and (orientation: landscape)",
}

export enum TabValues {
    Location = "location",
    Name = "name",
}

export enum Language {
    NL = "nl-BE",
    FR = "fr-BE",
}
