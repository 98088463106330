import {
    BalHeading,
    BalText,
} from "@baloise/design-system-next-components-react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

/**
 * SearchModule
 */

type BrandProps = {
    allBrands: boolean;
    excluded: Array<string>;
    included: Array<string>;
};

const BrandList: React.FC<BrandProps> = ({ allBrands, excluded, included }) => {
    const { t } = useTranslation();

    const formatBrandName = (brandName: string) => {
        return (
            brandName.charAt(0).toUpperCase() + brandName.toLowerCase().slice(1)
        );
    };

    if (allBrands && excluded.length === 0) {
        return (
            <>
                <BalHeading level="h6">
                    <>{t("selectedrepairer.brands")}</>
                </BalHeading>
                <BalText>
                    <>{t("selectedrepairer.allbrands")}</>
                </BalText>
            </>
        );
    }

    if (allBrands && excluded.length > 0) {
        return (
            <>
                <BalHeading level="h6">
                    <>{t("selectedrepairer.brands")}</>
                </BalHeading>
                <BalText>
                    <>{t("selectedrepairer.brandswithexcluded")}</>
                </BalText>
                <ul className="is-list mb-none">
                    {excluded.map((brand: string, key: number) => (
                        <li key={key} className="mb-xx-small">
                            <>{formatBrandName(brand)}</>
                        </li>
                    ))}
                </ul>
            </>
        );
    }

    return (
        <>
            <BalHeading level="h6">
                <>{t("selectedrepairer.brands")}</>
            </BalHeading>
            <BalText>
                <>{t("selectedrepairer.brandswithincluded")}</>
            </BalText>
            <ul className="is-list mb-none">
                {included.map((brand: string, key: number) => (
                    <li key={key} className="mb-xx-small">
                        <>{formatBrandName(brand)}</>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default BrandList;
