import React, { useEffect, useRef, useState } from "react";
import {
    Map,
    data,
    control,
    ControlStyle,
    AuthenticationType,
    ControlPosition,
    source,
    layer,
} from "azure-maps-control";
import bringDataIntoView from "../helper/bringDataIntoView";
import SpiderClusterManager from "../helper/SpiderClusterManager";
import * as style from "./BalAzureMaps.module.scss";
import DefaultHover from "../../../../assets/icons/default_hover.svg";
import PremiumHover from "../../../../assets/icons/premium_hover.svg";
import Premium from "../../../../assets/icons/premium.svg";
import Default from "../../../../assets/icons/default.svg";
import { useRecoilValue } from "recoil";
import { mapCameraState } from "@states/userData";

type balMapPropTypes = {
    mapData: any;
    refresh: boolean;
    selectARepairer: any;
    selectedRepairer: any;
};

const BalAzureMaps: React.FC<balMapPropTypes> = ({
    mapData,
    refresh,
    selectARepairer,
    selectedRepairer,
}) => {
    const mapElement: any = useRef(null);
    const [map, setMap]: any = useState();
    const [dataSource, setDataSource]: any = useState();
    const [symbolLayer, setSymbolLayer]: any = useState();
    const [clusterLayer, setClusterLayer]: any = useState();
    const [bubbleLayer, setBubbleLayer]: any = useState();

    const mapCamera = useRecoilValue(mapCameraState);

    const ZOOM_LEVEL = 15;

    useEffect(() => {
        if (dataSource && map) {
            if (mapData?.features?.length > 0) {
                const mapDataDeseriallized: data.FeatureCollection = mapData;
                dataSource.setShapes(mapDataDeseriallized);
                if (!selectedRepairer) {
                    bringDataIntoView(map, data);
                } else {
                    map.setCamera({
                        zoom: ZOOM_LEVEL,
                        center: [
                            selectedRepairer.coordinates.longitude,
                            selectedRepairer.coordinates.latitude,
                        ],
                    });
                }
            } else {
                dataSource.clear();
                map.setCamera(mapCamera);
            }

            // Center the map icons
        }
    }, [mapData, dataSource, selectedRepairer, map, mapCamera]);

    const highlight = (event: any) => {
        selectARepairer(null);
        let selectedShape = event?.shapes[0];
        if (selectedShape) {
            if (
                !selectedShape?.properties?.cluster ||
                typeof selectedShape?.properties?.cluster === "number"
            ) {
                let properties = selectedShape.properties
                    ? selectedShape.properties
                    : selectedShape?.getProperties();

                selectARepairer(properties.id);
            } else {
                selectARepairer(selectedShape.getProperties().id);
            }
        }
    };

    useEffect(() => {
        setMap(
            new Map(mapElement.current, {
                view: "Auto",
                authOptions: {
                    authType: AuthenticationType.subscriptionKey,
                    subscriptionKey:
                        "Y_HbZNVfnefedJpL4W5skDoC0G8sFq71NDz3UhKuAW0",
                },
                ...mapCamera,
            }),
        );
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (map) {
            map.events.add("ready", () => {
                map.controls.add(
                    new control.StyleControl({
                        style: ControlStyle.dark,
                    }),
                    {
                        position: ControlPosition.TopRight,
                    },
                );

                map.controls.add(new control.ZoomControl(), {
                    position: ControlPosition.TopRight,
                });

                setDataSource(
                    new source.DataSource(undefined, {
                        cluster: true,
                        clusterRadius: 45,
                        clusterMaxZoom: ZOOM_LEVEL,
                    }),
                );
            });
        }
    }, [map]);

    useEffect(() => {
        if (dataSource && map) {
            map.sources.add(dataSource);
            setBubbleLayer(
                new layer.BubbleLayer(dataSource, undefined, {
                    radius: 20,
                    color: "#9F52CC",
                    strokeWidth: 0,
                    filter: ["has", "point_count"], //Only rendered data points which have a point_count property, which clusters do.
                }),
            );

            setClusterLayer(
                new layer.SymbolLayer(dataSource, undefined, {
                    iconOptions: {
                        image: "none", //Hide the icon image.
                    },
                    textOptions: {
                        textField: ["get", "point_count_abbreviated"],
                        offset: [0, 0.4],
                        color: "#FFFFFF",
                        ignorePlacement: true,
                    },
                }),
            );

            map.imageSprite.add("default-icon", Default);
            map.imageSprite.add("default-hover-icon", DefaultHover);
            map.imageSprite.add("premium-icon", Premium);
            map.imageSprite.add("premium-hover-icon", PremiumHover);

            setSymbolLayer(
                new layer.SymbolLayer(dataSource, undefined, {
                    iconOptions: {
                        image: [
                            "match",
                            ["get", "type"],
                            // The get above will read the type prop from the item that is added.
                            // After that we need to define which type has which icon.
                            // normally it would have been written like this: "default", "default-icon", but our linting does not support that.
                            "default",
                            "default-icon",
                            "premium",
                            "premium-icon",
                            "default_hover",
                            "default-hover-icon",
                            "premium_hover",
                            "premium-hover-icon",
                            "default-icon",
                        ],
                        allowOverlap: true,
                        ignorePlacement: true,
                        size: 1,
                    },
                    filter: ["!", ["has", "point_count"]],
                }),
            );
        }
    }, [dataSource, map]);

    useEffect(() => {
        if (bubbleLayer) {
            map.layers.add(bubbleLayer);
            map.events.add("mouseover", bubbleLayer, () => {
                map.getCanvasContainer().style.cursor = "pointer";
            });
            map.events.add("mouseout", bubbleLayer, function () {
                map.getCanvasContainer().style.cursor = "grab";
            });
            // map.events.add("click", bubbleLayer, zoom);
            map.resize();
        }

        if (clusterLayer) {
            map.layers.add(clusterLayer);
            map.resize();
        }

        if (symbolLayer) {
            map.layers.add(symbolLayer);
            map.events.add("mouseover", symbolLayer, () => {
                map.getCanvasContainer().style.cursor = "pointer";
            });
            map.events.add("mouseout", symbolLayer, function () {
                map.getCanvasContainer().style.cursor = "grab";
            });
            map.events.add("click", symbolLayer, highlight);

            map.resize();

            bringDataIntoView(map, data);

            SpiderClusterManager(map, bubbleLayer, symbolLayer, highlight);
        }
        // eslint-disable-next-line
    }, [symbolLayer, bubbleLayer, clusterLayer]);

    useEffect(() => {
        if (refresh) {
            map?.resize();
        }
        // eslint-disable-next-line
    }, [refresh]);

    return (
        <>
            <div id="azMap" className={style.map} ref={mapElement}></div>
        </>
    );
};

export default BalAzureMaps;
