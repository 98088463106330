import { Footer, Header } from "@components";
import React from "react";
// import * as style from "./Layout.module.scss";

type layoutProps = {
    children?: any;
};

/**
 * Layout
 */
const Layout: React.FC<layoutProps> = ({ children }) => {
    return (
        <>
            <Header />
            <main className="container is-fluid">{children}</main>
            <Footer />
        </>
    );
};

export default Layout;
